import React from "react";
import { preferredLanguage } from "./../configs/ui-language-config";
import { GeneralTexts } from "./../languages/GeneralTexts";

import Icon from "@mdi/react"
import { mdiChevronDoubleLeft, mdiChevronDoubleRight } from "@mdi/js";

const texts = {
    ar: {
        "how-it-works"                      : "شاهد كيف نعمل",
        "how-it-works-for-investors"        : "كيف تعمل المنصة للمستثمر؟",
        "how-it-works-for-borrowers"        : "كيف تعمل المنصة للمقترض؟",
        "start-investing-now"               : "ابدأ الاستثمار الآن",
        "start-borrowing-now"               : "ابدأ الاقتراض الآن",
        "open-opportunities"                : "الفرص المتاحة",
        "open-investment-opportunities"     : "فرص الاستثمار المتاحة",
        "open-opportunities-hint"           : "فرص الاستثمار المتاحة حاليا على المنصة",
        "see-more"                          : "شاهد المزيد",
        "are-you-borrower"                  : "هل أنت صاحب شركة؟",
        "are-you-investor"                  : "هل أنت مستثمر؟",
        "know-more"                         : "اعرف المزيد",
        "how-to-get-loan"                   : "كيف تحصل على تمويل لمشروعك؟",
        "apply-for-loan"                    : "قدم طلب التمويل",
        "prepare-required-documents"        : "جهز الأوراق المطلوبة",
        "launch-crowdfunding-campaign"      : "سنطلق حملة التمويل",
        "get-loan-amount"                   : "استلم قيمة التمويل",
        "no-loan-requests"                  : "لا يوجد فرص استثمار متاحة حاليا",
        "signup"                            : "تسجيل",
        "login"                             : "دخول",
        "only-1000-egp"                     : "ابدأ بـ 1000 جنيه فقط",
        "start-investing-with-only-1000-egp": "يمكن للمستثمر ان يبدأ الاستثمار في أي فرصة متاحة بمبلغ يبدأ من 1000 جنيه فقط",
        "competitive-return-on-investment"  : "عائد تنافسي على الاستثمار",
        "investors-achieved-competitive-returns": "حقق المستثمرون على المنصة عوائد تنافسية على استثماراتهم بلغت ما يقرب من 20% سنويا",
        "risk-insurance"                    : "تأمين ضد المخاطر",
        "transactions-are-insured"          : "يتم التأمين على الصفقات التي يستثمر فيها المستثمرون ضد السطو والحريق والتلفيات",

        "intro-section-content-1": (
            <React.Fragment>
                {/*
                <span>أول منصة تمويل جماعي مصرية تتيح للمستثمرين الأفراد</span>
                <br />
                <span>إقراض الشركات الصغيرة والمتوسطة قروض قصيرة الأجل</span>
                */}
                <span>أول منصة تمويل جماعي مصرية، تتيح للمستثمرين الأفراد</span>
                <br />
                <span>إقراض الشركات الصغيرة والمتوسطة</span>
                <br />
                <span>قروض قصيرة الأجل</span>
            </React.Fragment>
        ),

        "intro-section-content-2": (
            <>
                <span>
                    <Icon
                        path={preferredLanguage.direction === "rtl" ? mdiChevronDoubleLeft : mdiChevronDoubleRight}
                        size="1.2em"
                        className="x-pos-r x-t-3"
                    />
                    &nbsp;
                    سهولة استخدام المنصة
                </span>
                <br />
                <span>
                    <Icon
                        path={preferredLanguage.direction === "rtl" ? mdiChevronDoubleLeft : mdiChevronDoubleRight}
                        size="1.2em"
                        className="x-pos-r x-t-3"
                    />
                    &nbsp;
                    إجراءات للحد من مخاطر الاستثمار
                </span>
                <br />
                <span>
                    <Icon
                        path={preferredLanguage.direction === "rtl" ? mdiChevronDoubleLeft : mdiChevronDoubleRight}
                        size="1.2em"
                        className="x-pos-r x-t-3"
                    />
                    &nbsp;
                    تحقيق عوائد تنافسية
                </span>
                <br />
                <span>
                    <Icon
                        path={preferredLanguage.direction === "rtl" ? mdiChevronDoubleLeft : mdiChevronDoubleRight}
                        size="1.2em"
                        className="x-pos-r x-t-3"
                    />
                    &nbsp;
                    إجراء تحليلات ائتمانية دقيقة
                </span>
            </>
        ),

        "borrower-section-content-1": (
            <span>إذا كنت صاحب شركة صغيرة أو متوسطة، فإن منصة أموال يمكن أن تساعدك وتيسر لك تدبير التمويل اللازم لاحتياجاتك، في اسرع وقت، وبأقل تكلفة، وأبسط إجراءات.</span>
        ),

        "borrower-section-content-2": (
            <span>
                <strong>خطوة 1</strong><br />
                سجل لنفسك حساب على المنصة كصاحب شركة، بالضغط على زر <strong>{GeneralTexts["signup-now"]}</strong>
            </span>
        ),

        "borrower-section-content-3": (
            <span>
                <strong>خطوة 2</strong><br />
                أضف بياناتك وبيانات الشركة والقرض المطلوب في الخانات المخصصة لذلك
            </span>
        ),

        "borrower-section-content-4": (
            <span>
                <strong>خطوة 3</strong><br />
                سنراجع البيانات، وعند الموافقة على الطلب، سنعرض حملة التمويل على المنصة
            </span>
        ),

        "borrower-section-content-5": (
            <span>
                <strong>خطوة 4</strong><br />
                عند نجاح الحملة وجمع المبلغ من المستثمرين، سنحوله إلى حسابك البنكي
            </span>
        ),

        "investor-section-content-1": (
            <span>أموال يمكن أن تساعدك على إدارة استثماراتك بكفاءة، وتحقيق عوائد تنافسية عليها، وتقليل مخاطر الاستثمار بتوزيع استثماراتك على أكثر من فرصة من الفرص المتاحة المدروسة جيدا.</span>
        ),

        "investor-section-content-2": (
            <span>
                <strong>خطوة 1</strong><br />
                سجل لنفسك حساب على المنصة كمستثمر، بالضغط على زر <strong>{GeneralTexts["signup-now"]}</strong>
            </span>
        ),

        "investor-section-content-3": (
            <span>
                <strong>خطوة 2</strong><br />
                تصفح فرص الاستثمار المتاحة على المنصة، وشارك في ما يناسبك منها
            </span>
        ),

        "investor-section-content-4": (
            <span>
                <strong>خطوة 3</strong><br />
                تابع حالة القروض التي شاركت في تمويلها، واعرف إلى أي مرحلة وصلت
            </span>
        ),

        "investor-section-content-5": (
            <span>
                <strong>خطوة 4</strong><br />
                عند سداد القرض سنخطرك، وسنحول لحسابك البنكي أصل المبلغ مع الأرباح
            </span>
        ),

        "finance-process-steps-content-1": (
            <span>قدم طلب التمويل من خلال النموذج المخصص لذلك بعد أن تقوم بالتسجيل في الموقع كصاحب مشروع.</span>
        ),

        "finance-process-steps-content-2": (
            <span>سوف نتواصل معك لنخبرك بالأوراق والمستندات المطلوب توافرها لكي يتم إطلاق حملة التمويل على المنصة.</span>
        ),

        "finance-process-steps-content-3": (
            <span>بعد توفير المستندات المطلوبة، ستنطلق حملة التمويل على المنصة، وسندعي المستثمرين للمشاركة فيها.</span>
        ),

        "finance-process-steps-content-4": (
            <span>بعد أن تغطي تعهدات المستثمرين قيمة المبلغ المطلوب، سوف يتم إيداع المبلغ في الحساب البنكي الذي سيخصص لذلك.</span>
        ),

        "intro-section-content-v2-1": (
            // <>
            //     أول منصة <span className="x-c-blue">إقراض جماعي</span> مصرية<br />
            //     تتيح <span className="x-d-ib" style={{ borderBottom: "2px solid #000" }}>للمستثمرين</span> إقراض <span className="x-d-ib" style={{ borderBottom: "2px solid #000" }}>الشركات</span> الصغيرة والمتوسطة<br />
            //     قروض <span className="x-c-crimson">قصيرة الأجل</span>
            // </>
            <>
                {/* أول منصة إقراض جماعي مصرية<br /> */}
                {/* تتيح للمستثمرين إقراض الشركات الصغيرة والمتوسطة<br /> */}
                {/* تتيح للمستثمرين إقراض الشركات الصغيرة والمتوسطة<br /> */}
                {/* قروض قصيرة الأجل */}
                أول منصة إقراض جماعي مصرية<br />
                تتيح للمستثمرين الاستثمار في القروض قصيرة الأجل<br />
                للشركات الصغيرة والمتوسطة
            </>
        ),

        "borrower-section-content-v2-1": (
            <span>إذا كنت صاحب شركة صغيرة أو متوسطة، فإن منصة أموال يمكن أن تساعدك وتيسر لك تدبير التمويل اللازم لاحتياجاتك، في اسرع وقت، وبأقل تكلفة، وأبسط إجراءات.</span>
        ),

        "borrower-section-content-v2-2": (
            <span>
                <span className="x-fz-12rem">1. </span>
                سجل لنفسك حساب على المنصة كصاحب شركة، بالضغط على زر <strong>{GeneralTexts["signup-now"]}</strong>
            </span>
        ),

        "borrower-section-content-v2-3": (
            <span>
                <span className="x-fz-12rem">2. </span>
                أضف بياناتك وبيانات الشركة والقرض المطلوب في الخانات المخصصة لذلك
            </span>
        ),

        "borrower-section-content-v2-4": (
            <span>
                <span className="x-fz-12rem">3. </span>
                سنراجع البيانات، وعند الموافقة على الطلب، سنعرض حملة التمويل على المنصة
            </span>
        ),

        "borrower-section-content-v2-5": (
            <span>
                <span className="x-fz-12rem">4. </span>
                عند نجاح الحملة وجمع المبلغ من المستثمرين، سنحوله إلى حسابك البنكي
            </span>
        ),

        "investor-section-content-v2-1": (
            <span>أموال يمكن أن تساعدك على إدارة استثماراتك بكفاءة، وتحقيق عوائد تنافسية عليها، وتقليل مخاطر الاستثمار بتوزيع استثماراتك على أكثر من فرصة من الفرص المتاحة المدروسة جيدا.</span>
        ),

        "investor-section-content-v2-2": (
            <span>
                <span className="x-fz-12rem">1. </span>
                سجل لنفسك حساب على المنصة كمستثمر، بالضغط على زر <strong>{GeneralTexts["signup-now"]}</strong>
            </span>
        ),

        "investor-section-content-v2-3": (
            <span>
                <span className="x-fz-12rem">2. </span>
                تصفح فرص الاستثمار المتاحة على المنصة، وشارك في ما يناسبك منها
            </span>
        ),

        "investor-section-content-v2-4": (
            <span>
                <span className="x-fz-12rem">3. </span>
                تابع حالة القروض التي شاركت في تمويلها، واعرف إلى أي مرحلة وصلت
            </span>
        ),

        "investor-section-content-v2-5": (
            <span>
                <span className="x-fz-12rem">4. </span>
                عند سداد القرض سنخطرك، وسنحول لحسابك البنكي أصل المبلغ مع الأرباح
            </span>
        ),
    },

    en: {
        "how-it-works"                      : "How It Works?",
        "how-it-works-for-investors"        : "How it works for Investors?",
        "start-investing-now"               : "Start Investing Now",
        "start-borrowing-now"               : "Start Funding Now",
        "how-it-works-for-borrowers"        : "How it works for Borrowers?",
        "open-opportunities"                : "Open Opportunities",
        "open-investment-opportunities"     : "Open Investment Opportunities",
        "open-opportunities-hint"           : "Loan requests that are currently open for crowdfunding",
        "see-more"                          : "See More",
        "are-you-borrower"                  : "Are You a Business Owner?",
        "are-you-investor"                  : "Are You an Investor?",
        "know-more"                         : "Know More",
        "how-to-get-loan"                   : "How to get funded?",
        "apply-for-loan"                    : "Apply For a Loan",
        "prepare-required-documents"        : "Prepare Your Papers",
        "launch-crowdfunding-campaign"      : "We Launch a Campaign",
        "get-loan-amount"                   : "You Get The Money",
        "no-loan-requests"                  : "There is no open opportunities right now",
        "signup"                            : "Signup",
        "login"                             : "Login",
        "only-1000-egp"                     : "Start With Only 1000 EGP",
        "start-investing-with-only-1000-egp": "Starting from 1000 EGP, the investor can start investing in any open opportunity",
        "competitive-return-on-investment"  : "Competitive ROI",
        "investors-achieved-competitive-returns": "Investors have achieved competitive returns on their investments of nearly 20% annually",
        "risk-insurance"                    : "Risk Insurance",
        "transactions-are-insured"          : "Transactions in which investors invest are insured against burglary, fire, and damage",

        "intro-section-content-1": (
            <React.Fragment>
                <span>Amwal is the first Egyptian debt-based</span>
                <br />
                <span>crowdfunding platform that lets retail</span>
                <br />
                <span>investors lend short-term loans to SMEs</span>
            </React.Fragment>
        ),

        "intro-section-content-2": (
            <React.Fragment>
                <span>
                    <Icon
                        path={preferredLanguage.direction === "rtl" ? mdiChevronDoubleLeft : mdiChevronDoubleRight}
                        size="1.2em"
                        className="x-pos-r _x-t-3"
                    />
                    &nbsp;
                    Easy-to-use platform
                </span>
                <br />
                <span>
                    <Icon
                        path={preferredLanguage.direction === "rtl" ? mdiChevronDoubleLeft : mdiChevronDoubleRight}
                        size="1.2em"
                        className="x-pos-r _x-t-3"
                    />
                    &nbsp;
                    Procedures to reduce investment risks
                </span>
                <br />
                <span>
                    <Icon
                        path={preferredLanguage.direction === "rtl" ? mdiChevronDoubleLeft : mdiChevronDoubleRight}
                        size="1.2em"
                        className="x-pos-r _x-t-3"
                    />
                    &nbsp;
                    Competitive returns on investment
                </span>
                <br />
                <span>
                    <Icon
                        path={preferredLanguage.direction === "rtl" ? mdiChevronDoubleLeft : mdiChevronDoubleRight}
                        size="1.2em"
                        className="x-pos-r _x-t-3"
                    />
                    &nbsp;
                    Perform accurate credit analysis
                </span>
            </React.Fragment>
        ),

        "borrower-section-content-1": (
            <span>If you are the owner of a small or medium company, Amwal platform can help you get the proper loan that meets your financial needs in a short time, with small fees, and through simple and straightforward procedures.</span>
        ),

        "borrower-section-content-2": (
            <span>
                <strong>Step 1</strong><br />
                Create an account for yourself as a business owner by clicking <strong>{GeneralTexts["signup-now"]}</strong>
            </span>
        ),

        "borrower-section-content-3": (
            <span>
                <strong>Step 2</strong><br />
                Enter your data, your company data, and data of the requested loan
            </span>
        ),

        "borrower-section-content-4": (
            <span>
                <strong>Step 3</strong><br />
                After approving your application, your loan's crowdfunding campaign begins
            </span>
        ),

        "borrower-section-content-5": (
            <span>
                <strong>Step 4</strong><br />
                When the campaign succeed, we'll transfer the money to your bank
            </span>
        ),

        "investor-section-content-1": (
            <span>Amwal can help you manage your investments efficiently, gaining competitive returns on them, and reducing investment risks by distributing your investments on multiple opportunities that have been carefully reviewed.</span>
        ),

        "investor-section-content-2": (
            <span>
                <strong>Step 1</strong><br />
                Create an account for yourself as an investor by clicking <strong>{GeneralTexts["signup-now"]}</strong>
            </span>
        ),

        "investor-section-content-3": (
            <span>
                <strong>Step 2</strong><br />
                Browse the open opportunities and participate in what suits you
            </span>
        ),

        "investor-section-content-4": (
            <span>
                <strong>Step 3</strong><br />
                Track the status of each loan you've participated in and monitor its progress
            </span>
        ),

        "investor-section-content-5": (
            <span>
                <strong>Step 4</strong><br />
                When the loan gets repaid, we'll transfer to you, your money & its returns
            </span>
        ),

        "finance-process-steps-content-1": (
            <span>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vestibulum mattis erat at mi pulvinar convallis.</span>
        ),

        "finance-process-steps-content-2": (
            <span>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vestibulum mattis erat at mi pulvinar convallis.</span>
        ),

        "finance-process-steps-content-3": (
            <span>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vestibulum mattis erat at mi pulvinar convallis.</span>
        ),

        "finance-process-steps-content-4": (
            <span>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vestibulum mattis erat at mi pulvinar convallis.</span>
        ),

        "intro-section-content-v2-1": (
            // <>
            //     The First Egyptian Crowdlending Platform<br />
            //     That Allows Investors Invest in The Short-Term Loans<br />
            //     of The Small & Medium Companies
            // </>
            <>
                The First Egyptian Crowdlending Platform<br />
                Enabling Retail Investors to Fund Short-Term Loans<br />
                of Small & Medium Size Businesses
            </>
        ),
    }}

export const HomeTexts = texts[preferredLanguage.alpha2code];
